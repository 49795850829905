import { Button, CircularProgress, Container, IconButton } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import FormDateTime from './formComponents/formDateTime';
import FormSubtitle from './formComponents/formSubTitle';
import { Link as RouterLink } from 'react-router-dom';
import UserSelector from './userSelector';
import PropertySelector from './propertySelector';
import useRole from '../hooks/useRole';
import Role from '../interfaces/role';
import FormBack from './formComponents/formBack';
import useCustomFunctions from '../hooks/useCustomFunctions';
import PropertyInterface from '../interfaces/property';
import SkillInterface from '../interfaces/skill';
import {
  DataGrid,
  GridValueFormatterParams,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { addressFormatter, printDateTime } from '../helpers';
import useUID from '../hooks/useUID';
import { USER_COL_WIDTH } from '../constants/width';
import { TABLE_DEFAULT_HEIGHT } from '../constants/height';
import cx from 'classnames';
import useCalenderTodayJobs from '../hooks/useCalenderTodayJobs';
import LaunchIcon from '@mui/icons-material/Launch';
export interface ResultData {
  view: string;
  id: string;
  date?: number;
  property?: PropertyInterface;
  type: string;
  assignee?: string;
  skill?: SkillInterface;
  contractor?: string;
  contractorID?: string;
  description?: string;
  subtaskID: string;
  assigneeID?: string;
}

interface CalenderTableProps {
  showSelectors?: boolean;
  tableHeight?: number;
  assigneeID?: string;
  className?: string;
  isHomeTable?: boolean;
}

export const calendarAllowedRoles = [Role.ADMIN, Role.MEMBER, Role.CONTRACTOR];

export const CalendarTable: React.FC<CalenderTableProps> = ({
  showSelectors,
  tableHeight,
  assigneeID,
  className,
  isHomeTable,
}) => {
  const role = useRole();
  const uid = useUID();
  const functions = useCustomFunctions();
  const [fromDefault, toDefault] = useMemo(() => {
    const t = moment().startOf('day').set('hour', 0); // This will return a copy of the Date that the moment uses
    const from = t.toDate().getTime();
    const to = t.clone().endOf('day').toDate().getTime();
    return [from, to];
  }, []);
  const [fromDate, setFromDate] = useState<number>(fromDefault);
  const [toDate, setToDate] = useState<number>(toDefault);
  const [contractor, setContractor] = useState<string | undefined>(undefined);
  const [property, setProperty] = useState<string | undefined>(undefined);
  const [assignee, setAssignee] = useState<string | undefined>(undefined);
  const [result, setResult] = useState<ResultData[]>([]);
  const [filteredResult, setFilteredResult] = useState<ResultData[]>([]);
  const [loading, setLoading] = useState(false);
  const todayJobs = useCalenderTodayJobs(assigneeID);

  // Guard for delayed result in case dates are changed very frequently.
  useEffect(() => {
    const filterDelayedRequest = result.filter(
      (item) => item.date && item.date >= fromDate && item.date <= toDate,
    );
    setFilteredResult(filterDelayedRequest);
  }, [fromDate, toDate, result]);

  useEffect(() => {
    if (!role || !uid) {
      return;
    }
    if (!calendarAllowedRoles.includes(role)) {
      return;
    }
    if (showSelectors) {
      setLoading(true);
      const fn = () => {
        functions
          .httpsCallable('calendar')({
            from: fromDate,
            to: toDate,
            propertyID: property,
            contractorID: role === Role.CONTRACTOR ? uid : contractor,
            assigneeID: assignee,
          })
          .then((resp) => {
            setResult(resp.data);
          })
          .finally(() => {
            setLoading(false);
          });
      };

      fn();
      const interval = setInterval(fn, 1000 * 60 * 15);
      return () => clearInterval(interval);
    } else {
      setResult(todayJobs);
    }
  }, [
    functions,
    setResult,
    setLoading,
    fromDate,
    toDate,
    contractor,
    property,
    assignee,
    assigneeID,
    role,
    todayJobs,
    uid,
    showSelectors,
  ]);

  return (
    <Container className={cx(className)}>
      {showSelectors && (
        <>
          <FormBack to="/" label="Home" />
          <FormSubtitle label="Calendar" />
          <div className="flex flex-wrap child:flex-grow child:flex-shrink sm:!-mx-1">
            <FormDateTime
              val={fromDate}
              onChange={(v) =>
                setFromDate(
                  v
                    ? moment(v).startOf('minute').toDate().getTime()
                    : fromDefault,
                )
              }
              label="From"
              fullWidth={false}
              // format="HH:mm, d MMMM, yyyy"
              className="sm:!mx-1"
            />
            <FormDateTime
              val={toDate}
              onChange={(v) =>
                setToDate(
                  v ? moment(v).endOf('minute').toDate().getTime() : toDefault,
                )
              }
              label="To"
              fullWidth={false}
              // format="HH:mm, d MMMM, yyyy"
              className="sm:!mx-1"
            />
            {role !== Role.CONTRACTOR && (
              <>
                <UserSelector
                  val={assignee || ''}
                  onChange={(v) =>
                    setAssignee(v && v.length > 0 ? v : undefined)
                  }
                  label="Assignee"
                  emptyLabel="ALL"
                  showEmpty={true}
                  fullWidth={false}
                  roles={['MEMBER', 'ADMIN']}
                  className="sm:!mx-1 !min-w-50"
                />
                <UserSelector
                  val={contractor || ''}
                  onChange={(v) =>
                    setContractor(v && v.length > 0 ? v : undefined)
                  }
                  label="Contractor"
                  emptyLabel="ALL"
                  showEmpty={true}
                  fullWidth={false}
                  roles={['CONTRACTOR']}
                  className="sm:!mx-1 !min-w-60"
                />
                <PropertySelector
                  val={property || ''}
                  onChange={(v) =>
                    setProperty(v && v.length > 0 ? v : undefined)
                  }
                  label="Property"
                  emptyLabel="ALL"
                  showEmpty={true}
                  fullWidth={false}
                  className="sm:!mx-1 !min-w-60"
                />
              </>
            )}
          </div>
          <div className="spacer" />
          <div className="spacer" />
        </>
      )}
      <div
        style={{
          display: 'flex',
          height: tableHeight || TABLE_DEFAULT_HEIGHT,
          marginBottom: 20,
        }}
      >
        {loading && <CircularProgress />}
        {!loading && (
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              rows={loading ? [] : filteredResult}
              loading={loading}
              columns={cols(role, isHomeTable)}
              density="compact"
            />
          </div>
        )}
      </div>
    </Container>
  );
};

const cols = (role: Role | undefined, isHomeTable: boolean | undefined) => [
  {
    width: 120,
    field: 'view',
    headerName: 'View',
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.type !== 'subtask') {
        if (role === Role.ADMIN || role === Role.MEMBER) {
          return (
            <Button
              variant="contained"
              color="primary"
              size="small"
              component={RouterLink}
              to={`/jobs/${params.row.subtaskID}`}
            >
              View
            </Button>
          );
        }
        return null;
      }
      return (
        <Button
          variant="contained"
          color="primary"
          size="small"
          component={RouterLink}
          to={`/jobs/${params.row.id}`}
        >
          View
        </Button>
      );
    },
  },
  {
    width: 120,
    field: 'subtaskID',
    headerName: 'ID',
    hide: isHomeTable !== true,
  },

  {
    width: 240,
    field: 'date',
    headerName: 'Appointment Date & Time',
    hide: isHomeTable === false,
    renderCell: (params: GridRenderCellParams) => {
      const from = params.value as number;
      const { jobDuration } = params.row;
      const to = moment(from).add(jobDuration, 'hour').format('HH:mm');
      return printDateTime(from) + ' - ' + to;
    },
  },
  {
    width: 130,
    field: 'type',
    headerName: 'Type',
    valueFormatter: (params: GridValueFormatterParams) =>
      params.value === 'subtask' ? 'Instruction' : 'Quote',
  },
  {
    width: 130,
    field: 'skill',
    headerName: 'Skill',
    valueFormatter: (params: GridValueFormatterParams) =>
      (params.value as SkillInterface).name,
  },
  {
    width: 385,
    field: 'property',
    headerName: 'Address',
    valueFormatter: (params: GridValueFormatterParams) =>
      addressFormatter(params.value as PropertyInterface),
  },
  {
    width: USER_COL_WIDTH + 20,
    field: 'contractor',
    headerName: 'Contractor',
    hide: role === Role.CONTRACTOR,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <>
          {params.row.contractor && (
            <span className="mr-2">{params.row.contractor}</span>
          )}
          {params.row.contractorID && (
            <IconButton
              color="primary"
              size="small"
              component={RouterLink}
              to={`/users/${params.row.contractorID}`}
              target="_blank"
            >
              <LaunchIcon fontSize="small" />
            </IconButton>
          )}
        </>
      );
    },
  },
  {
    width: USER_COL_WIDTH,
    field: 'assignee',
    headerName: 'Assignee',
    hide: role === Role.CONTRACTOR,
  },
  {
    width: 195,
    field: 'description',
    headerName: 'Description',
    valueFormatter: (params: GridValueFormatterParams) => {
      const val = params.value as string;
      return val && val.length > 50 ? val.substr(0, 50) + '...' : val || '';
    },
  },
];
